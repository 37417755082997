import { createSlice, createAction } from "@reduxjs/toolkit";

export const resetState = createAction("Reset_all");

const initialState = {
  error: "",
};

export const Orderlice = createSlice({
  name: "UI Slice",
  initialState,
  reducers: {
    setError: (state, action) => ({
      ...state,
      error: action.payload,
    }),
  },
});

export const { setError } = Orderlice.actions;

export default Orderlice.reducer;
