import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import authService from "./authServices";
import { extractError } from "../../utils/helper";

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "",
  company: localStorage.getItem("company")
    ? JSON.parse(localStorage.getItem("company"))
    : "",
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const login = createAsyncThunk(
  "auth/login",
  async (params, thunkAPI) => {
    try {
      return await authService.login(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loginOTP = createAsyncThunk(
  "auth/login/otp",
  async (query, thunkAPI) => {
    try {
      return await authService.loginOTP(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verify/email",
  async (query, thunkAPI) => {
    try {
      return await authService.verifyEmail(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (params, thunkAPI) => {
    try {
      return await authService.registerUser(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "auth/update/profile",
  async (params, thunkAPI) => {
    try {
      return await authService.updateUser(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const registerCompany = createAsyncThunk(
  "auth/registerCompany",
  async (params, thunkAPI) => {
    try {
      return await authService.registerCompany(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const checkCompanyStatus = createAsyncThunk(
  "auth/check/status",
  async (params, thunkAPI) => {
    try {
      return await authService.checkCompanyStatus(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const userStats = createAsyncThunk(
  "auth/stats",
  async (params, thunkAPI) => {
    try {
      return await authService.userStats(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    addUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    addCompany: (state, action) => ({
      ...state,
      company: action.payload,
    }),
    resetAPIState: (state, action) => ({
      ...state,
      isLoading: false,
      isError: false,
      isSuccess: false,
      message: "",
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.loginUser = action.payload;
        state.message = "success";
      })
      .addCase(login.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(loginOTP.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(loginOTP.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.loginUser = action.payload;
        state.message = "success";
      })
      .addCase(loginOTP.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.verifiedUser = action.payload;
        state.message = "success";
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.register_user = action.payload;
        state.message = "success";
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedUser = action.payload;
        state.message = "success";
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(registerCompany.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(registerCompany.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.register_company = action.payload;
        state.message = "success";
      })
      .addCase(registerCompany.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(checkCompanyStatus.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(checkCompanyStatus.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.companyStatus = action.payload;
        state.message = "success";
      })
      .addCase(checkCompanyStatus.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(userStats.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(userStats.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.userStats = action.payload;
        state.message = "success";
      })
      .addCase(userStats.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(resetState, () => initialState);
  },
});

export const { addUser, addCompany, resetAPIState } = authSlice.actions;

export default authSlice.reducer;
