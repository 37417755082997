import ApiInstance from "../../utils/axiosconfig";

const verifyQr = async (query) => {
  const response = await ApiInstance.get(`/qr/scan` + query);

  return response?.responseData;
};

const getScannedQRs = async (query) => {
  const response = await ApiInstance.get(`/qr/scan/getAll` + query);

  return response?.responseData;
};

const QrService = {
  verifyQr,
  getScannedQRs
};

export default QrService;
