import { useEffect } from "react";
import UserRegistration from "../components/user/userRegistration";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useAuthUser from "../hooks/useAuthUser";

import Full_Logo from "../assets/image/full_logo.svg";
import pattern_navy from "../assets/image/Pattern_Small_Navy.svg";

export default function Register() {
  const navigate = useNavigate();

  const { authUser } = useAuthUser();

  useEffect(() => {
    if (authUser && authUser?.isLogin) {
      navigate(parseInt(authUser.role) <= 2 ? "/user" : "/admin");
      return;
    }
  }, [authUser]);

  return (
    <div className="grid grid-cols-12 !bg-secondary min-h-screen ">
      <div
        className="py-5 max-lg:hidden lg:col-span-6 2xl:col-span-7 flex items-center justify-center min-h-screen relative"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${pattern_navy}') no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <img src={Full_Logo} className="h-[220px] w-full z-10" alt="logo" />
      </div>
      <div className="col-span-12 lg:col-span-6 2xl:col-span-5 flex items-center justify-center w-full min-h-screen overflow-y-auto !bg-primary">
        <div className="gap-2 p-4 w-full md:mx-20">
          <img
            src={Full_Logo}
            className="h-[100px] w-full lg:hidden"
            alt="logo"
          />
          <h3 className="text-center title">Customer Registartion</h3>
          <AnimatePresence>
            <UserRegistration isCustomer={true} />
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
