import ApiInstance from "../../utils/axiosconfig";

const getProduct = async (query) => {
  const response = await ApiInstance.get(`product/getAll` + query);

  return response?.responseData;
};

const createProduct = async (body) => {
  const response = await ApiInstance.post(`product/create`, body);

  return response?.responseData;
};

const addBulkProduct = async (query) => {
  const response = await ApiInstance.get(`product/add/bulk` + query);
  return response?.responseData;
};

const downloadBulkTemplate = async () => {
  const response = await ApiInstance.get(`product/download/template`);
  return response?.responseData;
};

const getProductById = async (id) => {
  const response = await ApiInstance.get(`product/getById?_id=${id}`);

  return response?.responseData;
};

const updateProduct = async (id, obj) => {
  const response = await ApiInstance.put(`product/update?_id=${id}`, obj);

  return response?.responseData;
};

const deleteProduct = async (id) => {
  const response = await ApiInstance.delete(`product/delete?_id=${id}`);

  return response?.responseData;
};

const productClassifications = async () => {
  const response = await ApiInstance.get(`product/classifications`);

  return response?.responseData;
};

const ProductService = {
  getProduct,
  getProductById,
  addBulkProduct,
  downloadBulkTemplate,
  createProduct,
  updateProduct,
  deleteProduct,
  productClassifications,
};

export default ProductService;
