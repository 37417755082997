import { Bars } from "react-loader-spinner";
import { Spinner } from "react-bootstrap";

export const FullScreenLoader = ({ loading, content }) => {
  return (
    <>
      {loading && (
        <div className="cover-spin">
          <Bars
            height="80"
            width="80"
            radius="9"
            color="#09cfab"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
    </>
  );
};

export function BtnLoader({ loading }) {
  return (
    <>
      {loading && (
        <Spinner
          animation="border"
          variant="primary"
          size="sm"
          className="mx-2"
          color="#685dd8"
        />
      )}
    </>
  );
}

export function ComponentLoader({ loading }) {
  return (
    <>
      {loading && (
        <Spinner
          animation="border"
          variant="primary"
          className="mx-1 p-4"
          color="#685dd8"
        />
      )}
    </>
  );
}
