import ApiInstance from "../../utils/axiosconfig";

const getTrademark = async (query) => {
  const response = await ApiInstance.get(`trademark/getAll` + query);

  return response?.responseData;
};

const createTrademark = async (body) => {
  const response = await ApiInstance.post(`trademark/create`, body);

  return response?.responseData;
};

const getTrademarkById = async (id) => {
  const response = await ApiInstance.get(`trademark/getById?_id=${id}`);

  return response?.responseData;
};

const updateTrademark = async (id, obj) => {
  const response = await ApiInstance.put(`trademark/update?_id=${id}`, obj);

  return response?.responseData;
};

const deleteTrademark = async (id) => {
  const response = await ApiInstance.delete(`trademark/delete?_id=${id}`);

  return response?.responseData;
};

const Trademarkervice = {
  getTrademark,
  getTrademarkById,
  createTrademark,
  updateTrademark,
  deleteTrademark,
};

export default Trademarkervice;
