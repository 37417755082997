import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import ProductService from "./productServices";
import { extractError } from "../../utils/helper";

export const getProduct = createAsyncThunk(
  "get-Product",
  async (query, thunkAPI) => {
    try {
      return await ProductService.getProduct(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductById = createAsyncThunk(
  "get-Product-by-id",
  async (id, thunkAPI) => {
    try {
      return await ProductService.getProductById(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createProduct = createAsyncThunk(
  "create-Product",
  async (params, thunkAPI) => {
    try {
      return await ProductService.createProduct(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addBulkProduct = createAsyncThunk(
  "add-bulk-Product",
  async (params, thunkAPI) => {
    try {
      return await ProductService.addBulkProduct(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "update-Product",
  async (obj, thunkAPI) => {
    try {
      const id = obj?.id;
      const data = obj?.formData;
      return await ProductService.updateProduct(id, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "delete-Product",
  async (id, thunkAPI) => {
    try {
      return await ProductService.deleteProduct(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProductClassifications = createAsyncThunk(
  "get-Product-classification",
  async (params, thunkAPI) => {
    try {
      return await ProductService.productClassifications(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  Product: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const Productlice = createSlice({
  name: "Product Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Product = action.payload;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(getProductById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Product = action.payload;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(addBulkProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBulkProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdProduct = action.payload;
      })
      .addCase(addBulkProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(createProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdProduct = action.payload;
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedProduct = action.payload;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedProduct = action.payload;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(getProductClassifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductClassifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.ProductClassifications = action.payload;
      })
      .addCase(getProductClassifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(resetState, () => initialState);
  },
});

export default Productlice.reducer;
