import ApiInstance from "../../utils/axiosconfig";

const getOrder = async (query) => {
  const response = await ApiInstance.get(`order/getAll` + query);

  return response?.responseData;
};

const printOrder = async (query) => {
  const response = await ApiInstance.get(`/order/print` + query);

  return response?.responseData;
};

const createOrder = async (body) => {
  const response = await ApiInstance.post(`order/create`, body);

  return response?.responseData;
};

const getOrderById = async (id) => {
  const response = await ApiInstance.get(`order/getById?_id=${id}`);

  return response?.responseData;
};

const updateOrder = async (id, obj) => {
  const response = await ApiInstance.put(`order/update?_id=${id}`, obj);

  return response?.responseData;
};

const deleteOrder = async (id) => {
  const response = await ApiInstance.delete(`order/delete?_id=${id}`);

  return response?.responseData;
};

const OrderService = {
  getOrder,
  printOrder,
  getOrderById,
  createOrder,
  updateOrder,
  deleteOrder,
};

export default OrderService;
