import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import adminService from "./adminService";
import { extractError } from "../../utils/helper";

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const getAllUsers = createAsyncThunk(
  "admin/user/getAll",
  async (query, thunkAPI) => {
    try {
      return await adminService.getAllUsers(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "admin/user/update",
  async (data, thunkAPI) => {
    try {
      let { query, params } = data;
      return await adminService.updateUser(query, params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAdminStats = createAsyncThunk(
  "admin/stats",
  async (params, thunkAPI) => {
    try {
      return await adminService.getAdminStats(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

export const adminSlice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: (buildeer) => {
    buildeer

      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.AllUsers = action.payload;
        state.message = "success";
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.updatedUser = action.payload;
        state.message = "success";
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getAdminStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdminStats.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.isSuccess = true;
        state.AdminStats = action.payload;
        state.message = "success";
      })
      .addCase(getAdminStats.rejected, (state, action) => {
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(resetState, () => initialState);
  },
});

export default adminSlice.reducer;
