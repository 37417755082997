import ApiInstance from "../../utils/axiosconfig";

const getAllUsers = async (query, params) => {
  const response = await ApiInstance.get(`/admin/user/getAll` + query, params);
  return response?.responseData;
};

const updateUser = async (query, params) => {
  const response = await ApiInstance.put(`/admin/user/update` + query, params);
  return response?.responseData;
};

const getAdminStats = async (params) => {
  const response = await ApiInstance.get(`/admin/stats`);
  return response?.responseData;
};

const adminService = {
  getAllUsers,
  updateUser,
  getAdminStats,
};

export default adminService;
