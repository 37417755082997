import React from "react";
import { BtnLoader } from "../loaders/loading";

export default function CustomButton({
  isLoading,
  children,
  className,
  ...props
}) {
  return (
    <button
      className={`px-3 py-2 text-white fw-bold w-100 text-center text-decoration-none fs-5 mt-3
        bg-btnPrimary hover:bg-opacity-80 transition-colors rounded-2 disabled:!bg-secondary disabled:!border  disabled:!border-btnPrimary disabled:cursor-wait ${className}`}
      {...props}
      disabled={isLoading}
    >
      {children}
      <BtnLoader loading={isLoading} />
    </button>
  );
}
