import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import trademarkReducer from "../features/trademark/trademarkSlice";
import productReducer from "../features/product/productSlice";
import orderReducer from "../features/order/orderSlice";
import qrReduer from "../features/qr/qrSlice";
import adminReduer from "../features/admin/adminSlice";
import uiReducer from "../features/ui/uiSlice";
import reportReducer from "../features/report/reportSlice";

export const store = configureStore({
  reducer: {
    admin: adminReduer,
    auth: authReducer,
    trademark: trademarkReducer,
    product: productReducer,
    order: orderReducer,
    qr: qrReduer,
    ui: uiReducer,
    report: reportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
