import _countries from "../utils/all-countries.json";
// import _gpc_classification from "../utils/gps_product_classification.json";

export const PrintSizes = [
  {
    id: 1,
    name: "1 x 1cm",
    height: "80",
    width: "80",
  },
  {
    id: 2,
    name: "3 x 3cm",
    height: "120",
    width: "120",
  },
  {
    id: 3,
    name: "5 x 5cm",
    height: "160",
    width: "160",
  },
];

export const UserRoles = [
  {
    id: 1,
    name: "Merchant",
    link: "/user",
  },
  {
    id: 2,
    name: "Customer",
    link: "/user",
  },
  // {
  //   id: 3,
  //   name: "Admin",
  //   link: "/admin",
  // },
];

export const AccountStatus = {
  UNBLOCKED: "Active",
  REJECTED: "Rejected",
  BLOCKED: "Blocked",
  PENDING: "Pending",
};

export const getCountries = () => {
  return Object.keys(_countries) || [];
};

export const getCities = (country) => {
  return _countries[country] || {};
};

export const CountriesOptions = Object.keys(_countries) || [];

// export const ProductClassificationOptions = [
//   ...new Set(
//     _gpc_classification["Schema"]
//       .flatMap((item) => item.Childs)
//       .flatMap((child) => child.Childs)
//       .map((child) => child.Title)
//   ),
// ].sort();
