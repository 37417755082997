import React from "react";
import { getFileFromServer } from "../../utils/helper";
import { Image } from "antd";
import moment from "moment";

export default function ReportView({ item }) {
  const originalProduct = item?.originalProduct;

  return (
    <div className="p-6">
      {/* Counterfeit Product Info */}
      <section className="mb-6 border-b border-secondary pb-4">
        <h2 className="text-3xl font-bold text-textLink mb-4">
          Counterfeit Product Information
        </h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <p className="text-lg mb-2">
              <strong>Product Name:</strong> {item?.productName}
            </p>
            <p className="text-lg mb-2">
              <strong>Product Code (SKU):</strong> {item?.productCode}
            </p>
            <p className="text-lg mb-2">
              <strong>Brand Name:</strong> {item?.brandName}
            </p>
            <p className="text-lg mb-2">
              <strong>Product Category:</strong> {item?.productCategory}
            </p>
            <p className="text-lg mb-2">
              <strong>Store Name:</strong> {item?.purchaseStoreName}
            </p>
            <p className="text-lg mb-2">
              <strong>Purchase Location:</strong> {item?.purchaseLocation}
            </p>
            <p className="text-lg mb-2">
              <strong>Batch Number:</strong> {item?.batchNumber}
            </p>
          </div>

          <div>
            <p className="text-lg mb-2">
              <strong>Purchase Price:</strong> {item?.purchasePrice}
            </p>
            <p className="text-lg mb-2">
              <strong>Purchase Date:</strong>{" "}
              {moment(item?.purchaseDate).format("DD-MM-YYYY")}
            </p>
          </div>
        </div>

        {/* Attachments */}
        {Array.isArray(item?.attachments) && item?.attachments.length > 0 && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Product Images:</h3>
            <div className="flex flex-wrap gap-4">
              {item?.attachments?.map((attachment) => (
                <Image
                  key={attachment.file}
                  height={150}
                  width={150}
                  src={getFileFromServer(attachment.file)}
                  alt="Product Image"
                  className="rounded-lg shadow-md"
                />
              ))}
            </div>
          </div>
        )}
      </section>

      {/* Original Product Info */}
      {originalProduct && (
        <section className="mt-6">
          <h2 className="text-3xl font-bold text-textLink mb-4">
            Original Product Information
          </h2>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <p className="text-lg mb-2">
                <strong>Original Name:</strong> {originalProduct?.name?.en}
              </p>
              <p className="text-lg mb-2">
                <strong>Manufacturer:</strong>{" "}
                {originalProduct?.manufacturer?.en}
              </p>
              <p className="text-lg mb-2">
                <strong>Origin Country:</strong>{" "}
                {originalProduct?.originCountry}
              </p>
            </div>

            <div>
              <p className="text-lg mb-2">
                <strong>Description:</strong> {originalProduct?.description?.en}
              </p>
            </div>
          </div>

          {/* Original Product Attachments */}
          {Array.isArray(originalProduct?.attachments) &&
            originalProduct?.attachments.length > 0 && (
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2">
                  Original Product Images:
                </h3>
                <div className="flex flex-wrap gap-4">
                  {originalProduct?.attachments?.map((attachment) => (
                    <Image
                      key={attachment.file}
                      height={150}
                      width={150}
                      src={getFileFromServer(attachment.file)}
                      alt="Original Product Image"
                      className="rounded-lg shadow-md"
                    />
                  ))}
                </div>
              </div>
            )}
        </section>
      )}
    </div>
  );
}
