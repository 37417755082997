import React from "react";
import {
  downloadFile,
  getFileFromServer,
  mapAttachments,
  userAvatar,
} from "../../utils/helper";
import { Table, Tooltip } from "antd";
import { AiFillEye, AiOutlineDownload } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";

export default function UserProfile({ item }) {
  const columns =
    parseInt(item?.role) === 1
      ? [
          {
            title: "Product Count",
            dataIndex: "productsCount",
            render: () => item?.productsCount || 0,
          },
          {
            title: "Order Count",
            dataIndex: "orderCount",
            render: () => item?.orderCount || 0,
          },
          {
            title: "Trademark Count",
            dataIndex: "trademarkCount",
            render: () => item?.trademarkCount || 0,
          },
          {
            title: "Total QR Codes Generated",
            dataIndex: "totalQrsGenerated",
            render: () => item?.totalQrsGenerated || 0,
          },
          {
            title: "Total QR Codes Scanned",
            dataIndex: "totalScannedQRs",
            render: () => item?.totalScannedQRs || 0,
          },
        ]
      : [
          {
            title: "Total QR Codes Scanned",
            dataIndex: "totalScannedQRs",
            render: () => item?.totalScannedQRs || 0,
          },
        ];

  return (
    <div className="p-6 pt-2">
      <div className="mb-6">
        <p className="text-3xl font-bold text-textLink mb-4">User Info</p>
        <div className="text-lg mb-2">
          <strong>Full Name:</strong> {item?.fullName}
        </div>
        <div className="text-lg mb-2">
          <strong>Email:</strong> {item?.email}
        </div>
        <div className="text-lg mb-2">
          <strong>Contact:</strong> {item?.phoneNumber}
        </div>
      </div>

      {item?.company && (
        <div className="mb-6">
          <p className="text-3xl font-bold text-textLink mb-4">
            Company Info
          </p>
          <div className="flex flex-column gap-4 mb-4">
            <img
              src={
                item?.company?.logo?.thumbnail
                  ? getFileFromServer(item?.company?.logo?.thumbnail)
                  : userAvatar
              }
              className="h-[100px] w-[150px] object-contain"
              alt="Company Logo"
            />
            <div>
              <div className="text-lg mb-2">
                <strong>Comapny Legal Name:</strong> {item?.company?.legalName}
              </div>
              <div className="text-lg mb-2">
                <strong>Comapny Name (Alias):</strong> {item?.company?.name}
              </div>
              <div className="text-lg mb-2">
                <strong>Email:</strong> {item?.company?.email}
              </div>
              <div className="text-lg mb-2">
                <strong>Phone Number:</strong> {item?.company?.phoneNumber}
              </div>
              <div className="text-lg mb-2">
                <strong>Address:</strong> {item?.company?.address}
              </div>
              <div className="text-lg mb-2">
                <strong>Registration No:</strong> {item?.company?.registrationNo}
              </div>
              <div className="text-lg mb-2">
                <strong>Registration Issue Date:</strong>{" "}
                {moment(item?.company?.registrationStatus?.issueDate).format(
                  "DD-MM-YY"
                )}
              </div>
              <div className="text-lg mb-2">
                <strong>Registration Expiry Date:</strong>{" "}
                {moment(item?.company?.registrationStatus?.expiryDate).format(
                  "DD-MM-YY"
                )}
              </div>
            </div>
          </div>
          <div className="overflow-auto">
            {item?.company?.attachments && (
              <Table
                columns={[
                  {
                    title: "Attachments",
                    dataIndex: "Attachment",
                    render: (_, render) => render?.title || 0,
                  },
                  {
                    title: "Action",
                    dataIndex: "Action",
                    render: (_, render) => (
                      <div className="flex items-center gap-2">
                        <Link
                          to={getFileFromServer(render?.file)}
                          target="_blank"
                        >
                          <Tooltip
                            className="!text-textPrimary fs-5 cursor-pointer"
                            title="View"
                          >
                            <AiFillEye />
                          </Tooltip>
                        </Link>
                        <div
                          className="fs-4 text-textPrimary cursor-pointer"
                          onClick={() =>
                            downloadFile(render?.file, render?.name)
                          }
                        >
                          <Tooltip title="Download">
                            <AiOutlineDownload />
                          </Tooltip>
                        </div>
                      </div>
                    ),
                  },
                ]}
                dataSource={mapAttachments(item?.company?.attachments)}
                pagination={false}
              />
            )}
          </div>
        </div>
      )}

      <div className="overflow-auto">
        <p className="text-3xl font-bold text-textPrimary mb-4">Stats</p>
        <Table columns={columns} dataSource={[item]} pagination={false} />
      </div>
    </div>
  );
}
