import ApiInstance from "../../utils/axiosconfig";

const login = async (params) => {
  const response = await ApiInstance.post(`user/login`, params);
  if (response.responseData?.jwToken) {
    localStorage.setItem("authToken", response?.responseData?.jwToken);
    return response?.responseData;
  }
  return null;
};

const loginOTP = async (query) => {
  const response = await ApiInstance.get(`user/login/otp` + query);
  if (response.responseData?.jwToken && response?.responseData?.user) {
    localStorage.setItem("user", JSON.stringify(response?.responseData?.user));
    localStorage.setItem(
      "company",
      JSON.stringify(response?.responseData?.company)
    );
    localStorage.setItem("authToken", response?.responseData?.jwToken);
    return response?.responseData;
  }
  return null;
};

const verifyEmail = async (query) => {
  const response = await ApiInstance.get(`user/verify/email` + query);
  if (response.responseData?.jwToken) {
    return response?.responseData;
  }
  return null;
};

const logout = async () => {
  await ApiInstance.get(`user/logout`);
};

const registerUser = async (params) => {
  const response = await ApiInstance.post(`user/register`, params);
  if (response.responseData?.jwToken) {
    localStorage.setItem("authToken", response?.responseData?.jwToken);
    return response?.responseData;
  }
  return null;
};

const updateUser = async (params) => {
  const response = await ApiInstance.put(`user/update/profile`, params);
  if (response?.responseData) {
    localStorage.setItem("user", JSON.stringify(response?.responseData));
    return response?.responseData;
  }
  return null;
};

const registerCompany = async (params) => {
  const response = await ApiInstance.post(`company/register`, params);
  if (response?.responseData?.company) {
    localStorage.setItem(
      "company",
      JSON.stringify(response?.responseData?.company)
    );
    return response?.responseData?.company;
  }
  return null;
};

const checkCompanyStatus = async (query = "") => {
  const response = await ApiInstance.get(`company/check/status` + query);
  return response?.responseData;
};

const userStats = async () => {
  const response = await ApiInstance.get(`user/stats`);
  return response?.responseData;
};

const authService = {
  login,
  loginOTP,
  verifyEmail,
  logout,
  registerUser,
  updateUser,
  registerCompany,
  checkCompanyStatus,
  userStats,
};

export default authService;
