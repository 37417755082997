import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import TrademarkService from "./trademarkServices";
import { extractError } from "../../utils/helper";

export const getTrademark = createAsyncThunk(
  "get-Trademark",
  async (query, thunkAPI) => {
    try {
      return await TrademarkService.getTrademark(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTrademarkById = createAsyncThunk(
  "get-Trademark-by-id",
  async (id, thunkAPI) => {
    try {
      return await TrademarkService.getTrademarkById(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createTrademark = createAsyncThunk(
  "create-Trademark",
  async (params, thunkAPI) => {
    try {
      return await TrademarkService.createTrademark(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateTrademark = createAsyncThunk(
  "update-Trademark",
  async (obj, thunkAPI) => {
    try {
      const id = obj?.id;
      const data = obj?.formData;
      return await TrademarkService.updateTrademark(id, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTrademark = createAsyncThunk(
  "delete-trademark",
  async (id, thunkAPI) => {
    try {
      return await TrademarkService.deleteTrademark(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  Trademark: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const Trademarklice = createSlice({
  name: "Trademark Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrademark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTrademark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Trademark = action.payload;
      })
      .addCase(getTrademark.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(getTrademarkById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTrademarkById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Trademark = action.payload;
      })
      .addCase(getTrademarkById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(createTrademark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTrademark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdTrademark = action.payload;
      })
      .addCase(createTrademark.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(updateTrademark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateTrademark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedTrademark = action.payload;
      })
      .addCase(updateTrademark.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(deleteTrademark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTrademark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedTrademark = action.payload;
      })
      .addCase(deleteTrademark.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(resetState, () => initialState);
  },
});

export default Trademarklice.reducer;
