import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import OrderService from "./orderServices";
import { extractError } from "../../utils/helper";

export const getOrder = createAsyncThunk(
  "get-Order",
  async (query, thunkAPI) => {
    try {
      return await OrderService.getOrder(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const printOrder = createAsyncThunk(
  "get-print-Order",
  async (query, thunkAPI) => {
    try {
      return await OrderService.printOrder(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrderById = createAsyncThunk(
  "get-Order-by-id",
  async (id, thunkAPI) => {
    try {
      return await OrderService.getOrderById(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createOrder = createAsyncThunk(
  "create-Order",
  async (params, thunkAPI) => {
    try {
      return await OrderService.createOrder(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "update-Order",
  async (obj, thunkAPI) => {
    try {
      const id = obj?.id;
      const data = obj?.formData;
      return await OrderService.updateOrder(id, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "delete-Order",
  async (id, thunkAPI) => {
    try {
      return await OrderService.deleteOrder(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  Order: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const Orderlice = createSlice({
  name: "Order Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Order = action.payload;
      })
      .addCase(getOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(printOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(printOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.PrintOrder = action.payload;
      })
      .addCase(printOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(getOrderById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrderById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Order = action.payload;
      })
      .addCase(getOrderById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(createOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createdOrder = action.payload;
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedOrder = action.payload;
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(deleteOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedOrder = action.payload;
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })
      .addCase(resetState, () => initialState);
  },
});

export default Orderlice.reducer;
