import ApiInstance from "../../utils/axiosconfig";

const submitReport = async (params) => {
  const response = await ApiInstance.post(`/report/create`, params);

  return response?.responseData;
};

const getAllReports = async (query) => {
  const response = await ApiInstance.get(`/report/getAll` + query);

  return response?.responseData;
};

const ReportService = {
  submitReport,
  getAllReports,
};

export default ReportService;
