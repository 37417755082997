import { useEffect, useState } from "react";
import UserRegistration from "../components/user/userRegistration";
import CompanyRegistration from "../components/company/companyRegistration";
import {
  MdAttachment,
  MdChevronRight,
  MdInfo,
  MdSupervisedUserCircle,
} from "react-icons/md";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useAuthUser from "../hooks/useAuthUser";
import Full_Logo from "../assets/image/full_logo.svg";
import pattern_navy from "../assets/image/Pattern_Small_Navy.svg";
import CompanyAttachments from "../components/company/companyAttachments";

export default function Register_Merchant() {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const { authUser } = useAuthUser();

  useEffect(() => {
    if (authUser && !authUser.isProfileComplete) {
      handleNext();
      return;
    }
    if (authUser && authUser?.isLogin) {
      navigate(parseInt(authUser.role) <= 2 ? "/user" : "/admin");
      return;
    }
  }, [authUser]);

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setStep((prev) => prev && prev - 1);
  };

  return (
    <div className="grid grid-cols-12 !bg-secondary min-h-screen ">
      <div
        className="py-5 max-lg:hidden lg:col-span-5 2xl:col-span-7 flex items-center justify-center min-h-screen relative"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${pattern_navy}') no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <img src={Full_Logo} className="h-[220px] w-full z-10" alt="logo" />
      </div>
      <div className="col-span-12 lg:col-span-7 2xl:col-span-5 flex items-center justify-center w-full min-h-screen overflow-y-auto !bg-primary">
        <div className="gap-2 p-4 w-full md:mx-10">
          <img
            src={Full_Logo}
            className="h-[100px] w-full lg:hidden"
            alt="logo"
          />
          <h3 className="text-center title">Merchant Registration</h3>
          <div className="flex flex-wrap items-center justify-center gap-4 my-4 transition-all">
            <div
              className={`flex items-center gap-2 transition-all ${
                step !== 1 && "opacity-50"
              }`}
            >
              <MdSupervisedUserCircle size={24} />
              User Info
            </div>

            <MdChevronRight size={24} />

            <div
              className={`flex items-center gap-2 transition-all ${
                step !== 2 && "opacity-50"
              }`}
            >
              <MdInfo size={24} />
              Company Info
            </div>

            <MdChevronRight size={24} />

            <div
              className={`flex items-center gap-2 transition-all ${
                step !== 3 && "opacity-50"
              }`}
            >
              <MdAttachment size={24} />
              Attachments
            </div>
          </div>

          {/* <p className="text-center">Login to your account to continue.</p> */}
          <AnimatePresence>
            {step === 1 && <UserRegistration handleNext={handleNext} />}
            {step === 2 && (
              <CompanyRegistration
                handleBack={handleBack}
                handleNext={handleNext}
              />
            )}
            {step === 3 && <CompanyAttachments handleBack={handleBack} />}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}
