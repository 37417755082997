import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ConfigProvider, theme } from "antd";
import { Suspense, lazy } from "react";

// Helper Imports
// import { QRRedirection } from "./utils/helper";
import useAuthUser from "./hooks/useAuthUser";
import { FullScreenLoader } from "./components/loaders/loading";
import ErrorModal from "./components/modal/ErrorModal";
import Login from "./pages/Login";
import RegisterMerchant from "./pages/Register_Merchant";
import RegisterCustomer from "./pages/Register_Customer";
import AllReports from "./pages/Admin/Reports/AllReports";

// Lazy-loaded Pages
// const VerifyQR = lazy(() => import("./pages/QR/verifyQr"));
const VerifyEmail = lazy(() => import("./pages/Verify_Email"));
const MainLayout = lazy(() => import("./components/MainLayout"));

/* Merchant Imports */
const MerchantDashboard = lazy(() => import("./pages/Merchant/Dashboard"));
const AddTrademark = lazy(() =>
  import("./pages/Merchant/Trademark/AddTrademark")
);
const AllTrademarks = lazy(() =>
  import("./pages/Merchant/Trademark/AllTrademarks")
);
const EditTrademark = lazy(() =>
  import("./pages/Merchant/Trademark/EditTrademark")
);
const AddProduct = lazy(() => import("./pages/Merchant/Product/AddProduct"));
const AllProducts = lazy(() => import("./pages/Merchant/Product/AllProduct"));
const EditProduct = lazy(() => import("./pages/Merchant/Product/EditProduct"));
const AllOrders = lazy(() => import("./pages/Merchant/Order/AllOrder"));
const AddOrder = lazy(() => import("./pages/Merchant/Order/AddOrder"));
const PrintOrder = lazy(() => import("./pages/Merchant/Order/PrintOrder"));

/* Customer Imports */
// const CustomerDashboard = lazy(() => import("./pages/Customer/Dashboard"));
const ScannedQrs = lazy(() => import("./pages/QR/ScannedQRs"));
const NotFound404 = lazy(() => import("./pages/NotFound_404"));

/* Admin Imports */
const AdminDashboard = lazy(() => import("./pages/Admin/Dashboard"));
const AllUsers = lazy(() => import("./pages/Admin/User/AllUsers"));
// const CustomerProfile = lazy(() => import("./pages/Customer/Profile"));
const MerchantProfile = lazy(() => import("./pages/Merchant/Profile"));
const AddBulkProduct = lazy(() =>
  import("./pages/Merchant/Product/AddBulkProduct")
);

function App() {
  const { authUser } = useAuthUser();
  const userRole = parseInt(authUser?.role) || 0;

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        components: {
          Menu: {
            colorBgContainer: "#1f2937",
            itemActiveBg: "#fff",
            itemSelectedColor: "#f0f0f0",
          },
          Table: {
            colorBgContainer: "transparent",
          },
        },
      }}
    >
      {/* Toast Container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="dark"
      />

      {/* UI Modals */}
      <ErrorModal />

      <Router>
        <Suspense fallback={<FullScreenLoader loading={true} />}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<NotFound404 />} />

            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/verify/email" element={<VerifyEmail />} />
            <Route
              path="/auth/register/merchant"
              element={<RegisterMerchant />}
            />
            <Route
              path="/auth/register/customer"
              element={<RegisterCustomer />}
            />

            {/* QR Redirection */}
            {/* <Route path="/qr/verify" element={<QRRedirection />} /> */}

            {/* Merchant Routes */}
            {userRole === 1 && (
              <Route path="/user" element={<MainLayout role={1} />}>
                <Route index element={<MerchantDashboard />} />
                <Route path="profile" element={<MerchantProfile />} />
                <Route path="add-trademark" element={<AddTrademark />} />
                <Route path="edit-trademark/:id" element={<EditTrademark />} />
                <Route path="all-trademark" element={<AllTrademarks />} />
                <Route path="add-product" element={<AddProduct />} />
                <Route path="add-bulk-product" element={<AddBulkProduct />} />
                <Route path="edit-product/:id" element={<EditProduct />} />
                <Route path="all-products" element={<AllProducts />} />
                <Route path="add-order" element={<AddOrder />} />
                <Route path="all-orders" element={<AllOrders />} />
                <Route path="print-order/:id" element={<PrintOrder />} />
                {/* <Route path="verify-qr" element={<VerifyQR />} /> */}
                <Route path="product/scanned" element={<ScannedQrs />} />
              </Route>
            )}

            {/* Customer Routes */}
            {/* {userRole === 2 && (
              <Route path="/user" element={<MainLayout role={2} />}>
                <Route index element={<CustomerDashboard />} />
                <Route path="profile" element={<CustomerProfile />} />
                <Route path="verify-qr" element={<VerifyQR />} />
                <Route path="product/scanned" element={<ScannedQrs />} />
              </Route>
            )} */}

            {/* Admin Routes */}
            {userRole === 3 && (
              <Route path="/admin" element={<MainLayout role={3} />}>
                <Route index element={<AdminDashboard />} />
                <Route path="users" element={<AllUsers />} />
                {/* <Route path="scanned" element={<ScannedQrs />} /> */}
                <Route path="reports" element={<AllReports />} />
              </Route>
            )}
          </Routes>
        </Suspense>
      </Router>
    </ConfigProvider>
  );
}

export default App;
