import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import ReportService from "./reportService";
import { extractError } from "../../utils/helper";

export const submitReport = createAsyncThunk(
  "create-report",
  async (params, thunkAPI) => {
    try {
      return await ReportService.submitReport(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllReports = createAsyncThunk(
  "get-all-reports",
  async (params, thunkAPI) => {
    try {
      return await ReportService.getAllReports(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  Reports: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const ReportSlice = createSlice({
  name: "Report Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(submitReport.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.isLoading = true;
      })
      .addCase(submitReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Report = action.payload;
        state.message = action?.payload?.message;
      })
      .addCase(submitReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(getAllReports.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.isLoading = true;
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.Reports = action.payload;
      })
      .addCase(getAllReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(resetState, () => initialState);
  },
});

export default ReportSlice.reducer;
