import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload, Modal } from "antd";
import { base_url } from "../../utils/baseUrl";
import { getFileFromServer } from "../../utils/helper";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function MultiFileUpload({
  label,
  uploadFiles = [],
  setUploadFiles,
  accept,
  isMulti,
  required = false,
  max = 5,
}) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded && uploadFiles?.length > 0) {
      const initialFileList = uploadFiles.map((file, index) => ({
        uid: index.toString(),
        name: file.name,
        status: "done",
        url: getFileFromServer(file.file),
        response: { responseData: { file } },
      }));
      setFileList(initialFileList);
      setIsLoaded(true);
    }
  }, [uploadFiles]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    const uploadedFiles = newFileList
      .filter((file) => file.status === "done")
      .map((file) => file.response?.responseData?.file);
    setUploadFiles(uploadedFiles);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const props = {
    name: "singleFile",
    action: `${base_url}common/upload-single`,
    accept,
    listType: "picture-card",
    fileList,
    onChange: handleChange,
    onPreview: handlePreview,
    multiple: isMulti,
    maxCount: max,
  };

  return (
    <div className="flex flex-column gap-3 mt-3">
      <label>
        {label}
        {required && <span className="text-danger"> *</span>}
      </label>
      <Upload className="text-textClr" {...props}>{fileList.length >= 5 ? null : uploadButton}</Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
}
