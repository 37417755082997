import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import QrService from "./qrServices";
import { extractError } from "../../utils/helper";

export const verifyQr = createAsyncThunk(
  "verify-qr",
  async (query, thunkAPI) => {
    try {
      return await QrService.verifyQr(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getScannedQRs = createAsyncThunk(
  "scanned-qr",
  async (query, thunkAPI) => {
    try {
      return await QrService.getScannedQRs(query);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const resetState = createAction("Reset_all");

const initialState = {
  QR: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const QRSlice = createSlice({
  name: "QR Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyQr.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyQr.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.QR = action.payload;
        state.message = action?.payload?.message;
      })
      .addCase(verifyQr.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(getScannedQRs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getScannedQRs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.QR = action.payload;
      })
      .addCase(getScannedQRs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = extractError(action.payload);
      })

      .addCase(resetState, () => initialState);
  },
});

export default QRSlice.reducer;
