import React, { useState } from "react";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import OtpInput from "react-otp-input";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";

const CustomInput = (props) => {
  const {
    type,
    label,
    placeholder,
    i_id,
    i_class,
    name,
    val,
    onChng,
    onBlr,
    disabled = false,
    isAutoComplete = false,
    isPhoneNumber = false,
    isPassword = false,
    isOTP = false,
    required = false,
  } = props;

  const [isFocus, setIsFocus] = useState("");
  const [isVisible, setIsVisible] = useState("");

  return (
    <div className="flex flex-col gap-y-1 mt-3">
      <label>
        {label}
        {required && <span className="text-danger"> *</span>}
      </label>
      {isPassword ? (
        <div className="relative">
          <input
            type={isVisible ? "text" : "password"}
            className={`form-control ${i_class} py-3 px-3 !bg-primary outline-none focus:!shadow-none active:!shadow-none border !border-secondary transition-colors !text-textClr disabled:!bg-secondary/30 disabled:cursor-not-allowed focus:!border-btnPrimary rounded-2`}
            id={i_id}
            placeholder={placeholder}
            name={name}
            value={val}
            onChange={onChng}
            onBlur={onBlr}
            autoComplete={!isAutoComplete ? "new-password" : "false"}
            disabled={disabled}
          />
          <div
            className="absolute top-[18px] right-4 cursor-pointer"
            onClick={() => setIsVisible((prev) => !prev)}
          >
            {!isVisible ? (
              <BsEyeFill color="#09cfab" size={24} />
            ) : (
              <BsEyeSlash color="#09cfab" size={24} />
            )}
          </div>
        </div>
      ) : isPhoneNumber ? (
        <PhoneInput
          placeholder={placeholder}
          id={i_id}
          name={name}
          value={val}
          onChange={onChng}
          onBlur={() => setIsFocus(false)}
          onFocus={() => setIsFocus(true)}
          autoComplete={!isAutoComplete ? "new-password" : "false"}
          className={`bg-transparent p-3 outline-none focus:!shadow-none active:!shadow-none border !border-secondary transition-colors !text-textClr disabled:!bg-secondary/30 disabled:cursor-not-allowed ${i_class} ${
            isFocus && "!border-btnPrimary"
          } rounded-2`}
          disabled={disabled}
        />
      ) : isOTP ? (
        <OtpInput
          value={val}
          onChange={onChng}
          numInputs={6}
          renderInput={(props) => (
            <input
              onBlur={() => setIsFocus(false)}
              onFocus={() => setIsFocus(true)}
              type="tel"
              {...props}
            />
          )}
          containerStyle={{
            display: "flex",
            flexWrap: "wrap",
            gap: window.innerWidth < 992 ? "6px" : "10px",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
          inputStyle={{
            background: "#1f2937",
            height: window.innerWidth < 992 ? "42px" : "60px",
            // width: window.innerWidth < 992 ? "42px" : "50px",
            flex: "1",
            color: "#f0f0f0",
            fontSize: window.innerWidth < 992 ? "28px" : "32px",
            border: "1px solid #09cfab",
            fontWeight: 700,
            borderRadius: "10px",
          }}
          inputType="tel"
        />
      ) : (
        <input
          type={type}
          className={`form-control ${i_class} py-3 px-3 !bg-primary outline-none focus:!shadow-none active:!shadow-none border !border-secondary transition-colors !text-textClr disabled:!bg-secondary/30 disabled:cursor-not-allowed focus:!border-btnPrimary rounded-2`}
          id={i_id}
          placeholder={placeholder}
          name={name}
          value={val}
          onChange={onChng}
          onBlur={onBlr}
          autoComplete={!isAutoComplete ? "new-password" : "false"}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default CustomInput;
